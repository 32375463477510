<script setup>
import { computed, inject, ref, watch } from "vue";
import iPipelineLogo from "../../assets/images/iPipelineLogo.png";
import underWriteMeLogo from "../../assets/images/underWriteMeLogo2.png";
import IPipelineIntegration from "./IPipelineIntegration.vue";
import UnderWriteMeIntegration from "./UnderWriteMeIntegration.vue";
import { useUserIntegrations } from "../../queries/useUserIntegrations.js";
import { useMutation } from "@tanstack/vue-query";
import axios from 'axios';
import { ElButton, ElRadio } from 'element-plus'

const userService = inject('UserService');


const { data: integrations } = useUserIntegrations();

const currentIntegrations = computed(() =>
  integrations?.value?.map((i) => i.integrationType)
);
const selectedIntegration = ref("");
const savedIntegration = ref("");

const userDetails = computed(() => userService.getUserDetails());
const defaultIntegration = ref(userDetails.value.defaultIntegration);

const dialogVisible = ref(false);

watch(() => userDetails.value, (newValue) => {
  defaultIntegration.value = newValue.defaultIntegration;
});


const { mutate: setIntegrationDefault } = useMutation({
  mutationFn: (value) => {
    defaultIntegration.value = value;
    axios.post("api/Pricing/SetUserDefaultIntegration/" + value)
  },
  onSuccess: () => {
    dialogVisible.value = false;
  }
});

const selectPricing = (integration) => {
  selectedIntegration.value = integration;
}

const goHome = () => { window.location.href = "/";}

function showDialog(value) {
  dialogVisible.value = true;
  savedIntegration.value = value;
}


</script>
<template>
  <div class="mainDiv">
    <h1>
      We have integrated with third party underwriting and quote providers to enable you to compare quality alongside
      price.
    </h1>

    <div class="content">
      <div>
          <p>
              If you have an account with any of the following solutions, we can pull through quotes. <b>Click on the supplier logo to add your details to link or unlink to the services</b>:
          </p>
      </div>
      <section class="flex-container">
        <div class="item">
          <div @click="selectPricing('IPIPELINE')"
            :class="{ 'logo': true, 'selected': selectedIntegration === 'IPIPELINE' }">
            <img alt="iPipeline" :src="iPipelineLogo" />
            <div v-if="currentIntegrations?.includes('IPIPELINE')" class="active">
              Linked
            </div>
          </div>
          <div>
            <el-radio v-model="defaultIntegration" label="IPIPELINE"
              :disabled="!currentIntegrations?.includes('IPIPELINE')" @change="setIntegrationDefault('IPIPELINE')"> Make
              default </el-radio>
          </div>
        </div>
        <div class="item">
          <div @click="selectPricing('UNDERWRITEME')"
            :class="{ 'logo': true, 'underwriteme': true, 'selected': selectedIntegration === 'UNDERWRITEME' }">
            <img alt="underWriteMe" :src="underWriteMeLogo" />
            <div v-if="currentIntegrations?.includes('UNDERWRITEME')" class="active">
              Linked
            </div>
          </div>
          <div>
            <el-radio v-model="defaultIntegration" label="UNDERWRITEME"
              :disabled="!currentIntegrations?.includes('UNDERWRITEME')" @change="setIntegrationDefault('UNDERWRITEME')">
              Make default </el-radio>
          </div>
        </div>
        <div class="item">
          <div class=" logo noHover">
            <p>I do not want to include price in my comparisons</p>
          </div>
          <div>
            <el-radio v-model="defaultIntegration" label="non-pricing" @change="setIntegrationDefault('non-pricing')">
              Make default </el-radio>
          </div>
        </div>
      </section>
      <div>
        <p style="margin-top: unset;">The default service you select is the one we will use to generate pricing and
          quotes.
          You can change this at any time by clicking in “My Account” and selecting “reset price settings”.</p>
      </div>
      <IPipelineIntegration v-if="selectedIntegration === 'IPIPELINE'" @save-credentials="showDialog" />
      <UnderWriteMeIntegration v-if="selectedIntegration === 'UNDERWRITEME'" @save-credentials="showDialog" />
    </div>
    <br>
    <div style="display: flex; justify-content: center;">
      <el-button v-if="defaultIntegration" type="primary" @click="goHome()"> Continue to ProtectionGuruPro </el-button>
      <el-button v-else disabled type="info"> Select a default pricing option to continue</el-button>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="Thank you for linking your account." width="35%" :before-close="handleClose">
    <span><b>Would you like to make {{ savedIntegration }} the default service that ProtectionGuruPro uses to obtain price
        information?</b> <br><br>
      You can change the default at any time by clicking “My Account” and selecting “Price settings”.</span>
    <template #footer>
      <span class="dialog-footer">
        <div style="display: flex;">
          <el-button @click="dialogVisible = false">No, thanks.</el-button>
          <el-button type="primary" @click="setIntegrationDefault(savedIntegration)"> Yes, please. </el-button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.logo {
  border: 1px solid #3c3c3c;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 9rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.logo img {
  width: 100%;
  object-fit: cover;
}

.logo.underwriteme {
  padding: 0;
  background-color: #ffffff;
}

.logo.underwriteme img {
  object-fit: cover;
}

.noHover {
  cursor: default
}

.logo .active {
  position: absolute;
  inset: 0;
  top: auto;
  background-color: #49A267;
  color: var(--el-color-white);
}

.flex-container a {
  font-weight: bold;
}

.flex-container a.default,
.flex-container a.default:hover {
  border: 1px solid var(--el-color-primary);
  text-decoration: none;
  cursor: default;
  width: 100%;
  color: var(--el-color-primary);
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  text-align: center;
}

.logo.selected {
  border: 3px solid var(--el-color-primary);

}

.mainDiv {
  padding: 42px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 1044px;
  min-width: 1044px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 110px;
  position: relative;
}

.Paragraph2TC {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #191d23;
  padding-left: 15px;
  padding-right: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
}

.flex-container {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}
</style>
